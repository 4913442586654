import { addActionBarItem } from '@vendure/admin-ui/core';
import { gql } from 'graphql-tag';
import { firstValueFrom, switchMap } from 'rxjs';

import { FondyPaymentIntent } from './graphql/generated-admin-types';

const CREATE_FONDY_PAYMENT_INTENT = gql`
  mutation CreateFondyPaymentIntent($input: FondyPaymentIntentInput!) {
    createFondyPaymentIntent(input: $input) {
      ... on FondyPaymentIntent {
        url
      }
      ... on FondyPaymentIntentError {
        errorCode
        message
      }
    }
  }
`;

const GET_PAYMENT_METHOD_LIST = gql`
  query GetPaymentMethodList($options: PaymentMethodListOptions!) {
    paymentMethods(options: $options) {
      items {
        enabled
        handler {
          code
        }
      }
    }
  }
`;

export default [
  addActionBarItem({
    id: 'my-button',
    label: 'Fondy URL',
    locationId: 'order-detail',
    buttonColor: 'primary',
    icon: 'credit-card',
    onClick: async (event, context) => {
      try {
        const orderId = context.route.snapshot.params.id;
        const {
          createFondyPaymentIntent: { url },
        } = await firstValueFrom<{
          createFondyPaymentIntent: FondyPaymentIntent;
        }>(
          context.dataService.mutate(CREATE_FONDY_PAYMENT_INTENT, {
            input: {
              paymentMethodCode: 'fondy-payment',
              orderId,
            },
          }),
        );
        await navigator.clipboard.writeText(url);
        context.notificationService.success('Посилання на оплату скопійовано!');
      } catch (error) {
        if (error instanceof Error) {
          context.notificationService.error(
            'Виникла помилка: ' + error.message,
          );
        }
      }
    },
    buttonState: (context) => {
      return context.route.data.pipe(
        switchMap((data) => data.detail.entity),
        switchMap((order: any) => {
          return context.dataService
            .query(GET_PAYMENT_METHOD_LIST, { options: { take: 10 } })
            .mapSingle((result: any) => {
              const hasFondyPayment = result.paymentMethods.items.some(
                (item: any) => {
                  return (
                    item.handler.code === 'fondy-payment-handler' &&
                    item.enabled
                  );
                },
              );
              return {
                disabled: false,
                visible: order.state === 'ArrangingPayment' && hasFondyPayment,
              };
            });
        }),
      );
    },
  }),
];
