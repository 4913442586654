import { CdsButton } from '@cds/react/button';
import { DataService } from '@vendure/admin-ui/core';
import { FormField, useInjector } from '@vendure/admin-ui/react';
import { ID } from '@vendure/core';
import gql from 'graphql-tag';
import React, { FC, useEffect } from 'react';
import { switchMap } from 'rxjs';

import {
  CreateProductVariantPartMutation,
  GetProductVariantPartsQuery,
  ProductVariantPart,
} from '../../../graphql/generated-admin-types';
import RichTextEditor from '../../RichTextEditor/RichTextEditor';
import ProductVariantPartImage from '../AddNewPoductVariantPart/ProductVariantPartForm/ProductVariantPartImage/ProductVariantPartImage';
import { GET_PRODUCT_VARIANT_PARTS } from '../ProductVariantPartList/ProductVariantPartList';

const UPDATE_PRODUCT_VARIANT_PART = gql`
  mutation UpdateProductVariantPart($input: UpdateProductVariantPartInput!) {
    updateProductVariantPart(input: $input) {
      id
    }
  }
`;

type Props = {
  parentProductVariantId: ID;
  productVariantPart: ProductVariantPart;
  onClose: () => void;
};

const EditProductVariantPart: FC<Props> = ({
  productVariantPart,
  onClose,
  parentProductVariantId,
}) => {
  const [languageCode, setLanguageCode] = React.useState<string>();
  const dataService = useInjector(DataService);
  const [isLoading, setIsLoading] = React.useState(false);
  const [assetIds, setAssetsIds] = React.useState<string[]>(
    productVariantPart.assets?.map((item) => item.id) || [],
  );

  const defaultValues = (productVariantPart.translations.find(
    (translation) => translation.languageCode === languageCode,
  ) || {}) as { name: string; description: string };

  useEffect(() => {
    const subscription = dataService.client
      .uiState()
      .stream$.subscribe((data) =>
        setLanguageCode(data.uiState.contentLanguage),
      );

    return () => subscription.unsubscribe();
  }, [dataService.client]);

  const handleSave = (e: React.FormEvent) => {
    e.preventDefault();
    if (!productVariantPart) return;
    setIsLoading(true);

    const form = new FormData(e.target as HTMLFormElement);
    const name = form.get('name');
    const description = form.get('description');

    try {
      dataService
        .mutate<CreateProductVariantPartMutation>(UPDATE_PRODUCT_VARIANT_PART, {
          input: {
            assetIds,
            id: productVariantPart.id,
            featuredAssetId: assetIds?.[0],
            translations: [{ languageCode, name, description }],
          },
        })
        .pipe(
          switchMap(
            () =>
              dataService.query<GetProductVariantPartsQuery>(
                GET_PRODUCT_VARIANT_PARTS,
                {
                  productVariantId: parentProductVariantId,
                },
              ).single$,
          ),
        )
        .subscribe(() => {
          onClose();
        });
    } finally {
      setIsLoading(false);
    }
  };

  if (!languageCode) return null;

  return (
    <div
      id={`part-edit-${productVariantPart.id}`}
      style={{
        background: 'white',
        padding: 16,
        borderRadius: 5,
        marginBottom: 15,
        border: '1px solid var(--color-component-border-200)',
      }}
    >
      <div
        style={{
          fontWeight: 'bold',
          fontSize: 15,
          marginBottom: 10,
          color: 'black',
        }}
      >
        Редагування
      </div>

      <ProductVariantPartImage
        iniAssets={(productVariantPart.assets || []) as any[]}
        onChange={setAssetsIds}
      />

      <form onSubmit={handleSave}>
        <div
          style={{
            fontSize: 15,
            marginTop: 10,
            marginBottom: 10,
            fontWeight: 500,
          }}
        >
          {productVariantPart.partProductVariant.name}
        </div>

        <FormField label="Назва">
          <input
            type="text"
            name="name"
            defaultValue={defaultValues.name}
            required
          />
        </FormField>

        <FormField label="Опис">
          <RichTextEditor
            name="description"
            defaultValue={defaultValues.description}
          />
        </FormField>

        <div
          style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}
        >
          <button className="button secondary" onClick={onClose}>
            Скасувати
          </button>
          <CdsButton
            type="submit"
            style={{ height: 40, marginLeft: 10 }}
            loadingState={isLoading ? 'loading' : 'default'}
          >
            Зберегти
          </CdsButton>
        </div>
      </form>
    </div>
  );
};

export default EditProductVariantPart;
