import { DataService } from '@vendure/admin-ui/core';
import {
  useFormControl,
  ReactFormInputOptions,
  FormField,
  useDataService,
  useInjector,
} from '@vendure/admin-ui/react';
import React, { FC, useEffect } from 'react';

import { ProductVariantPartSelect } from '../ProductVariantPartSelect/ProductVariantPartSelect';

export const BundledVariantSelect: FC<ReactFormInputOptions> = () => {
  const { value, setFormValue } = useFormControl();

  return (
    <ProductVariantPartSelect value={value} onValueChange={setFormValue} />
  );
};
