import { registerReactCustomDetailComponent } from '@vendure/admin-ui/react';

import ClientWidget from './components/ClientWidget/ClientWidget';
import ShippingWidget from './components/ShippingWidget/ShippingWidget';

export default [
  registerReactCustomDetailComponent({
    locationId: 'draft-order-detail',
    component: ClientWidget,
  }),
  registerReactCustomDetailComponent({
    locationId: 'draft-order-detail',
    component: ShippingWidget,
  }),
];
