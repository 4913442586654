import { Country } from '@vendure/admin-ui/core';
import { FormField } from '@vendure/admin-ui/react';
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';

type Props = {
  availableCountries?: Array<Partial<Country>>;
};

const GeneralShippingAddress: FC<Props> = ({ availableCountries }) => {
  const { register } = useFormContext();

  return (
    <div
      style={{
        gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
        display: 'grid',
        rowGap: 10,
        columnGap: 20,
        marginTop: 10,
      }}
    >
      <div style={{ gridColumn: 'span 3' }}>
        <FormField label="Країна">
          <select {...register('countryCode', { required: true })}>
            {availableCountries?.map(({ name, code }) => (
              <option value={code} key={code}>
                {name}
              </option>
            ))}
          </select>
        </FormField>
      </div>

      <FormField label="Регіон/Штат">
        <input
          {...register('province')}
          placeholder="Регіон/Штат"
          type="text"
        />
      </FormField>

      <FormField label="Населений пункт">
        <input
          {...register('city', { required: true })}
          placeholder="Населений пункт"
          type="text"
        />
      </FormField>

      <FormField label="Індекс">
        <input {...register('postalCode')} placeholder="Індекс" type="text" />
      </FormField>

      <div style={{ gridColumn: 'span 3' }}>
        <FormField label="Адреса">
          <input
            {...register('streetLine1', { required: true })}
            placeholder="Адреса"
            type="text"
          />
        </FormField>
      </div>
    </div>
  );
};

export default GeneralShippingAddress;
