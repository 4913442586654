import { CdsButton } from '@cds/react/button';
import React, { FC } from 'react';

type Props = {
  isDirty: boolean;
  hasInitialValues: boolean;
  isLoading?: boolean;
};

const SaveButton: FC<Props> = ({ isDirty, hasInitialValues, isLoading }) => {
  if (!isDirty) return null;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 10,
      }}
    >
      <CdsButton type="submit" loadingState={isLoading ? 'loading' : 'default'}>
        {hasInitialValues && isDirty ? 'Оновити' : 'Зберегти'}
      </CdsButton>
    </div>
  );
};

export default SaveButton;
