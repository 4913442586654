import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import SharedProviders_0_0 from './extensions/fondy-extension/providers';
import SharedProviders_1_0 from './extensions/mono-extension/providers';
import SharedProviders_2_0 from './extensions/nova-poshta-extension/providers';
import SharedProviders_3_0 from './extensions/draft-order-extension/providers';
import SharedProviders_4_0 from './extensions/product-bundle-extension/providers';


@NgModule({
    imports: [CommonModule, ],
    providers: [...SharedProviders_0_0, ...SharedProviders_1_0, ...SharedProviders_2_0, ...SharedProviders_3_0, ...SharedProviders_4_0],
})
export class SharedExtensionsModule {}
