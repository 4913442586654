import { DataService } from '@vendure/admin-ui/core';
import { FormField, useInjector } from '@vendure/admin-ui/react';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import { firstValueFrom, map } from 'rxjs';

const GET_NOVA_POSHTA_CITIES = gql`
  query GetNovaPoshtaCities($name: String!) {
    novaPoshtaCities(input: { name: $name, limit: 10 }) {
      data {
        Description
      }
    }
  }
`;

const GET_NOVA_POSHTA_WAREHOUSES = gql`
  query GetNovaPoshtaWarehouses($cityName: String!, $search: String!) {
    novaPoshtaWarehouses(
      input: { cityName: $cityName, limit: 10, findByString: $search }
    ) {
      data {
        Description
      }
    }
  }
`;

const NovaPoshtaShippingAddress: FC = () => {
  const { control, setValue } = useFormContext();
  const dataService = useInjector(DataService);
  const city = useWatch({
    name: 'city',
    control,
  });

  const loadCities = (inputValue: string) =>
    firstValueFrom(
      dataService
        .query(GET_NOVA_POSHTA_CITIES, { name: inputValue })
        .mapSingle((data: any) => data?.novaPoshtaCities?.data)
        .pipe(
          map((cities: any[]) =>
            cities.map(({ Description }) => ({
              value: Description,
              label: Description,
            })),
          ),
        ),
    );

  const loadWarehouses = (inputValue: string) =>
    firstValueFrom(
      dataService
        .query(GET_NOVA_POSHTA_WAREHOUSES, {
          cityName: city,
          search: inputValue,
        })
        .mapSingle((data: any) => data?.novaPoshtaWarehouses?.data)
        .pipe(
          map((warehouses: any[]) =>
            warehouses.map(({ Description }) => ({
              value: Description,
              label: Description,
            })),
          ),
        ),
    );

  return (
    <div style={{ marginTop: 10 }}>
      <FormField label="Населений пункт">
        <Controller
          control={control}
          name="city"
          rules={{ required: true }}
          render={({ field: { value, onChange, ref } }) => (
            <AsyncSelect
              ref={ref}
              styles={{
                container: (baseStyles) => ({
                  ...baseStyles,
                  width: '100%',
                }),
              }}
              value={value ? { value, label: value } : null}
              className="custom-auto-complete"
              onChange={(v) => {
                if (!v) return;
                onChange(v.value);
                setValue('streetLine1', null);
              }}
              placeholder="Населений пункт"
              defaultOptions
              loadOptions={loadCities}
              autoFocus={!value}
              menuPlacement="top"
            />
          )}
        />
      </FormField>

      {!!city && (
        <div style={{ marginTop: 10 }}>
          <FormField label="Відділення">
            <Controller
              control={control}
              name="streetLine1"
              rules={{ required: true }}
              render={({ field: { value, onChange, ref } }) => (
                <AsyncSelect
                  ref={ref}
                  styles={{
                    container: (baseStyles) => ({
                      ...baseStyles,
                      width: '100%',
                    }),
                  }}
                  key={city}
                  className="custom-auto-complete"
                  value={value ? { value, label: value } : null}
                  onChange={(v) => {
                    const warehouse = v?.value;
                    onChange(warehouse);
                  }}
                  placeholder="Відділення"
                  defaultOptions
                  loadOptions={loadWarehouses}
                  autoFocus={!value}
                  menuPlacement="top"
                />
              )}
            />
          </FormField>
        </div>
      )}
    </div>
  );
};

export default NovaPoshtaShippingAddress;
