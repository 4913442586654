import { DataService } from '@vendure/admin-ui/core';
import {
  useFormControl,
  FormField,
  useDataService,
  useInjector,
  useQuery,
} from '@vendure/admin-ui/react';
import { ProductVariant } from '@vendure/core';
import gql from 'graphql-tag';
import React, { FC, useEffect } from 'react';
import Select from 'react-select';
import { firstValueFrom, map } from 'rxjs';

import { GetPartsFacetValueIdsQuery } from '../../graphql/generated-admin-types';

const GET_PARTS_FACET_VALUE_IDS = gql`
  query GetPartsFacetValueIds {
    partsFacetValueIds {
      ids
    }
  }
`;

type Props = {
  value?: ProductVariant;
  onValueChange: (value?: ProductVariant) => void;
};

export const ProductVariantPartSelect: FC<Props> = ({
  onValueChange,
  value,
}) => {
  const dataService = useInjector(DataService);
  const [options, setOptions] = React.useState<any[]>([]);
  const { data } = useQuery<GetPartsFacetValueIdsQuery>(
    GET_PARTS_FACET_VALUE_IDS,
  );

  useEffect(() => {
    if (!data?.partsFacetValueIds?.ids?.length) return;

    dataService.facet
      .getFacetValues({
        take: 100,
        filter: { id: { in: data?.partsFacetValueIds?.ids } },
      })
      .single$.subscribe(({ facetValues }) => {
        const ids = facetValues.items.map((facetValue) => facetValue.id);

        dataService.product
          .getProductVariantsSimple({
            take: 100,
            filter: {
              facetValueId: { in: ids },
            },
          })
          .mapSingle(({ productVariants }) => productVariants.items)
          .pipe(
            map((variants) =>
              variants.map((variant) => ({
                value: variant,
                label: variant.name,
              })),
            ),
          )
          .subscribe(setOptions);
      });
  }, [data?.partsFacetValueIds?.ids, dataService]);

  return (
    <div style={{ minWidth: 300, minHeight: 38, margin: '5px 0' }}>
      <Select
        styles={{
          container: (baseStyles) => ({
            ...baseStyles,
            width: '100%',
          }),
        }}
        className="custom-auto-complete"
        value={value ? { value, label: value.name } : null}
        onChange={(v) => {
          onValueChange(v?.value);
        }}
        placeholder="Варіант"
        options={options}
        autoFocus={!value}
        menuPlacement="top"
      />
    </div>
  );
};
