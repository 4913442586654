import { FormField } from '@vendure/admin-ui/react';
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { ShippingMethodQuote } from '../../../../graphql/generated-admin-types';

export type ShippingMethodWithHandlerCode = ShippingMethodQuote & {
  fulfillmentHandlerCode?: string;
};

type Props = {
  shippingMethods?: ShippingMethodWithHandlerCode[];
};

const ShippingMethodSelect: FC<Props> = ({ shippingMethods }) => {
  const { register } = useFormContext();

  return (
    <FormField label="Спосіб доставки">
      <select {...register('shippingMethodId', { required: true })}>
        {shippingMethods?.map(({ name, id, code, metadata }) => (
          <option value={id} key={id}>
            {name}
          </option>
        ))}
      </select>
    </FormField>
  );
};

export default ShippingMethodSelect;
