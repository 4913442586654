import { plusIcon } from '@cds/core/icon';
import { CdsIcon } from '@vendure/admin-ui/react';
import { ID } from '@vendure/core';
import React, { FC } from 'react';

import ProductVariantPartForm from './ProductVariantPartForm/ProductVariantPartForm';

type Props = {
  parentProductVariantId: ID;
};

const AddNewProductVariantPart: FC<Props> = ({ parentProductVariantId }) => {
  const [isCreateFormVisible, setIsCreateFormVisible] = React.useState(false);

  const onDisplayFormClick = () => {
    setIsCreateFormVisible(true);
  };

  const onHideFormClick = () => {
    setIsCreateFormVisible(false);
  };

  return (
    <div>
      {isCreateFormVisible ? (
        <ProductVariantPartForm
          onClose={onHideFormClick}
          parentProductVariantId={parentProductVariantId}
        />
      ) : (
        <button className="button secondary" onClick={onDisplayFormClick}>
          <CdsIcon icon={plusIcon} /> Додати Комплектуючі
        </button>
      )}
    </div>
  );
};

export default AddNewProductVariantPart;
