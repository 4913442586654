import { paperclipIcon } from '@cds/core/icon';
import {
  closestCenter,
  DndContext,
  MouseSensor,
  useSensor,
} from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
} from '@dnd-kit/sortable';
import {
  AssetPickerDialogComponent,
  ModalService,
} from '@vendure/admin-ui/core';
import { CdsIcon, useInjector } from '@vendure/admin-ui/react';
import { Asset } from '@vendure/core';
import React, { FC, useEffect } from 'react';

import { SortableImage } from './SortableImage';

type Props = {
  iniAssets?: Asset[];
  onChange: (value: string[]) => void;
};

const ProductVariantPartImage: FC<Props> = ({ iniAssets, onChange }) => {
  const [items, setItems] = React.useState<string[]>([]);
  const [assets, setAssets] = React.useState<Record<string, Asset>>({});
  const sensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });
  const modalService = useInjector(ModalService);

  useEffect(() => {
    if (iniAssets) {
      setAssets((prevState) => ({
        ...prevState,
        ...iniAssets.reduce((acc, a) => ({ ...acc, [a.id.toString()]: a }), {}),
      }));
      setItems((prevState) =>
        Array.from(
          new Set([...prevState, ...iniAssets.map((a) => a.id.toString())]),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onChange(items);
  }, [items, onChange]);

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((data) => {
        const oldIndex = data.indexOf(active.id);
        const newIndex = data.indexOf(over.id);

        return arrayMove(data, oldIndex, newIndex);
      });
    }
  };

  const handleRemove = (id: string) => {
    setAssets((prevState) => {
      const updatedState = { ...prevState };
      delete updatedState[id];
      return updatedState;
    });
    setItems((prevState) => prevState.filter((i) => i !== id));
  };

  return (
    <div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
          gridGap: '10px',
        }}
      >
        <DndContext
          sensors={[sensor]}
          modifiers={[restrictToHorizontalAxis]}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={items}
            strategy={horizontalListSortingStrategy}
          >
            {items.map((id) => (
              <SortableImage
                key={id}
                id={id}
                asset={assets[id]}
                onRemove={handleRemove}
              />
            ))}
          </SortableContext>
        </DndContext>
      </div>
      <button
        className="button secondary"
        style={{ marginTop: items.length ? 10 : 0 }}
        onClick={() =>
          modalService
            .fromComponent(AssetPickerDialogComponent, {
              size: 'xl',
            })
            // eslint-disable-next-line no-console
            .subscribe((data = []) => {
              setAssets((prevState) => ({
                ...prevState,
                ...data?.reduce(
                  (acc, a) => ({ ...acc, [a.id.toString()]: a }),
                  {},
                ),
              }));
              setItems((prevState) =>
                Array.from(
                  new Set([...prevState, ...data?.map((a) => a.id.toString())]),
                ),
              );
            })
        }
      >
        <CdsIcon icon={paperclipIcon} />
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Додати медіа-об'єкт
      </button>
    </div>
  );
};

export default ProductVariantPartImage;
