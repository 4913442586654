import { ReactDataTableComponentProps } from '@vendure/admin-ui/react';
import React, { FC } from 'react';

const BundleItemTableColumn: FC<ReactDataTableComponentProps> = ({
  rowItem,
}) => {
  return <div>{rowItem.customFields.childVariant?.name}</div>;
};

export default BundleItemTableColumn;
