import {
  registerReactDataTableComponent,
  registerReactFormInputComponent,
  registerReactCustomDetailComponent,
} from '@vendure/admin-ui/react';

import { BundledVariantSelect } from './components/Input/BundledVariantSelect';
import ProductVariantPartWidget from './components/ProductVariantPartWidget/ProductVariantPartWidget';
import BundleItemTableColumn from './components/Table/BundleItemTableColumn';

export default [
  registerReactFormInputComponent(
    'bundled-variant-select',
    BundledVariantSelect,
  ),
  registerReactDataTableComponent({
    tableId: 'order-detail-list',
    columnId: 'childVariant',
    component: BundleItemTableColumn,
  }),
  registerReactCustomDetailComponent({
    locationId: 'product-variant-detail',
    component: ProductVariantPartWidget,
  }),
];
