import { ProsemirrorService } from '@vendure/admin-ui/core';
import { useRichTextEditor } from '@vendure/admin-ui/react';
import React, {
  ChangeEvent,
  InputHTMLAttributes,
  forwardRef,
  useEffect,
  useState,
} from 'react';

export type RichTextEditorType = InputHTMLAttributes<HTMLInputElement> & {
  attributes?: Record<string, string>;
  readOnly?: boolean;
  onMount?: (editor: ProsemirrorService) => void;
  defaultValue?: string;
};

type Props = {
  readOnly?: boolean;
} & any;

const RichTextEditor = forwardRef<HTMLInputElement, Props>(
  (props: RichTextEditorType, ref) => {
    const [data, setData] = useState<string>('');
    const initialLoad = React.useRef(0);
    const { readOnly, defaultValue, ...rest } = props;
    const { ref: _ref, editor } = useRichTextEditor({
      attributes: props.attributes,
      isReadOnly: () => readOnly || false,
      onTextInput: (text) => {
        setData(text);
        if (props.onChange) {
          props.onChange({
            target: { value: text },
          } as ChangeEvent<HTMLInputElement>);
        }
        if (ref && 'current' in ref && ref.current) {
          ref.current.value = text;
          const event = new Event('input', {
            bubbles: true,
            cancelable: true,
          });
          ref.current.dispatchEvent(event);
        }
      },
    });

    useEffect(() => {
      if (!editor) return;

      initialLoad.current += 1;
      if (props.onMount) props.onMount(editor);

      // TODO: Remove it when vendure will fix useRichTextEditor hook
      if (defaultValue && initialLoad.current === 2) {
        editor.update(defaultValue);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editor]);

    return (
      <div className="react-reach-text-editor">
        <div ref={_ref} />
        <input type="hidden" value={data} {...rest} ref={ref} />
      </div>
    );
  },
);

RichTextEditor.displayName = 'RichTextEditor';

export default RichTextEditor;
