import {
  useFormControl,
  ReactFormInputOptions,
  FormField,
} from '@vendure/admin-ui/react';
import React, { FC, useEffect } from 'react';

export const BOX_SIZES = [
  { label: 'Мала', value: '17x14x6' },
  { label: 'Велика', value: '19x14x8' },
];

export const BoxSizeSelect: FC<ReactFormInputOptions> = () => {
  const { value, setFormValue } = useFormControl();

  useEffect(() => {
    setFormValue(BOX_SIZES[0].value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ minWidth: 212, maxWidth: 212, marginTop: 5 }}>
      <FormField>
        <select
          value={value}
          onChange={(event) => setFormValue(event.target.value)}
        >
          {BOX_SIZES.map(({ label, value: optionValue }) => (
            <option value={optionValue} key={optionValue}>
              {label}
            </option>
          ))}
        </select>
      </FormField>
      <div style={{ paddingLeft: 5, fontSize: 12 }}>{value}</div>
    </div>
  );
};
