import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ModalService } from '@vendure/admin-ui/core';
import { useInjector } from '@vendure/admin-ui/react';
import React, { FC } from 'react';
import { EMPTY, map, switchMap } from 'rxjs';

import { ProductVariantPart } from '../../../graphql/generated-admin-types';

type Props = {
  item: ProductVariantPart;
  languageCode: string;
  onRemoveClick: (id: string) => void;
  onEditClick: (id: string) => void;
};

const ProductVariantListItem: FC<Props> = ({
  item,
  onRemoveClick,
  onEditClick,
  languageCode,
}) => {
  const modalService = useInjector(ModalService);
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({ id: item.id });

  const { name, description } =
    item.translations.find(
      (translation) => translation.languageCode === languageCode,
    ) || {};

  return (
    <div
      ref={setNodeRef}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: 'white',
        borderRadius: 5,
        border: '1px solid var(--color-component-border-200)',
        padding: 16,
        cursor: 'default',
        transform: CSS.Transform.toString(transform),
        position: 'relative',
        transition,
      }}
      {...attributes}
    >
      <div style={{ flex: 1 }}>
        <div style={{ display: 'flex' }}>
          {item.assets?.map((asset) => (
            <img
              key={asset.id}
              src={asset.preview}
              alt={asset.name}
              style={{ width: 100, height: 100, marginRight: 8 }}
            />
          ))}
        </div>
        <div style={{ fontSize: 15, marginTop: 5, fontWeight: 500 }}>
          {item.partProductVariant.name}
        </div>
        {name && (
          <div style={{ fontSize: 15, marginTop: 5, fontWeight: 500 }}>
            {name}
          </div>
        )}
        {description && (
          <div dangerouslySetInnerHTML={{ __html: description }} />
        )}
      </div>

      <button
        {...listeners}
        ref={setActivatorNodeRef}
        className="button"
        style={{ position: 'absolute', top: 10, right: 10 }}
      >
        <svg viewBox="0 0 20 20" width="12">
          <path
            d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001
           4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0
            6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"
          ></path>
        </svg>
      </button>

      <div>
        <button
          className="button primary"
          style={{ marginRight: 10 }}
          onClick={() => {
            onEditClick(item.id);
            setTimeout(() => {
              document
                .getElementById(`part-edit-${item.id}`)
                ?.scrollIntoView({ block: 'start', behavior: 'smooth' });
            }, 300);
          }}
        >
          Редагувати
        </button>
        <button
          className="button danger"
          onClick={() => {
            modalService
              .dialog({
                title: 'Ввидалити',
                body: 'Ви впевнені що хочете видалити цей коиплектуючий ?',
                buttons: [
                  { type: 'secondary', label: 'Скасувати' },
                  { type: 'danger', label: 'Видалити', returnValue: true },
                ],
              })
              .subscribe((result) => {
                if (!result) return;
                onRemoveClick(item.id);
              });
          }}
        >
          Видалити
        </button>
      </div>
    </div>
  );
};

export default ProductVariantListItem;
