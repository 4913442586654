import { paperclipIcon, plusIcon } from '@cds/core/icon';
import { CdsButton } from '@cds/react/button';
import {
  closestCenter,
  DndContext,
  MouseSensor,
  useSensor,
} from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
} from '@dnd-kit/sortable';
import {
  AssetPickerDialogComponent,
  DataService,
  ModalService,
} from '@vendure/admin-ui/core';
import { CdsIcon, FormField, useInjector } from '@vendure/admin-ui/react';
import { Asset, ID, ProductVariant } from '@vendure/core';
import gql from 'graphql-tag';
import React, { FC, useEffect } from 'react';
import { switchMap } from 'rxjs';

import {
  CreateProductVariantPartMutation,
  GetProductVariantPartsQuery,
} from '../../../../graphql/generated-admin-types';
import { BundledVariantSelect } from '../../../Input/BundledVariantSelect';
import { ProductVariantPartSelect } from '../../../ProductVariantPartSelect/ProductVariantPartSelect';
import RichTextEditor from '../../../RichTextEditor/RichTextEditor';
import { GET_PRODUCT_VARIANT_PARTS } from '../../ProductVariantPartList/ProductVariantPartList';

import ProductVariantPartImage from './ProductVariantPartImage/ProductVariantPartImage';
import { SortableImage } from './ProductVariantPartImage/SortableImage';

const CREATE_PRODUCT_VARIANT_PART = gql`
  mutation CreateProductVariantPart($input: CreateProductVariantPartInput!) {
    createProductVariantPart(input: $input) {
      id
    }
  }
`;

type Props = {
  parentProductVariantId: ID;
  onClose: () => void;
};

const ProductVariantPartForm: FC<Props> = ({
  onClose,
  parentProductVariantId,
}) => {
  const [languageCode, setLanguageCode] = React.useState<string>();
  const dataService = useInjector(DataService);
  const [isLoading, setIsLoading] = React.useState(false);
  const [assetIds, setAssetsIds] = React.useState<string[]>([]);
  const [productVariantPart, setProductVariantPart] =
    React.useState<ProductVariant>();

  useEffect(() => {
    const subscription = dataService.client
      .uiState()
      .stream$.subscribe((data) =>
        setLanguageCode(data.uiState.contentLanguage),
      );

    return () => subscription.unsubscribe();
  }, [dataService.client]);

  const handleCreate = (e: React.FormEvent) => {
    e.preventDefault();
    if (!productVariantPart) return;
    setIsLoading(true);

    const form = new FormData(e.target as HTMLFormElement);
    const name = form.get('name');
    const description = form.get('description');

    try {
      dataService
        .mutate<CreateProductVariantPartMutation>(CREATE_PRODUCT_VARIANT_PART, {
          input: {
            assetIds,
            featuredAssetId: assetIds?.[0],
            partProductVariantId: productVariantPart.id,
            parentProductVariantId,
            translations: [{ languageCode, name, description }],
          },
        })
        .pipe(
          switchMap(
            () =>
              dataService.query<GetProductVariantPartsQuery>(
                GET_PRODUCT_VARIANT_PARTS,
                {
                  productVariantId: parentProductVariantId,
                },
              ).single$,
          ),
        )
        .subscribe(() => {
          onClose();
        });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        background: 'white',
        padding: 16,
        borderRadius: 5,
        border: '1px solid var(--color-component-border-200)',
      }}
    >
      <ProductVariantPartImage onChange={setAssetsIds} />
      <form onSubmit={handleCreate}>
        <div style={{ marginTop: 10 }}>
          <ProductVariantPartSelect
            value={productVariantPart}
            onValueChange={setProductVariantPart}
          />
        </div>
        <FormField label="Назва">
          <input type="text" name="name" required />
        </FormField>

        <FormField label="Опис">
          <RichTextEditor name="description" />
        </FormField>
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}
        >
          <button className="button secondary" onClick={onClose}>
            Скасувати
          </button>
          <CdsButton
            type="submit"
            style={{ height: 40, marginLeft: 10 }}
            loadingState={isLoading ? 'loading' : 'default'}
          >
            Зберегти
          </CdsButton>
        </div>
      </form>
    </div>
  );
};

export default ProductVariantPartForm;
