import { DataService } from '@vendure/admin-ui/core';
import { FormField, useInjector } from '@vendure/admin-ui/react';
import { Order } from '@vendure/core';
import gql from 'graphql-tag';
import React, { FC, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input/react-hook-form-input';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { firstValueFrom, map } from 'rxjs';

import SaveButton from '../SaveButton/SaveButton';

const GET_CUSTER_LIST = gql`
  query GetCustomerList($options: CustomerListOptions) {
    customers(options: $options) {
      items {
        id
        createdAt
        updatedAt
        title
        firstName
        lastName
        phoneNumber
        emailAddress
        user {
          id
          verified
          __typename
        }
        __typename
      }
      totalItems
      __typename
    }
  }
`;

const GET_CUSTOMER = gql`
  query GetCustomer($id: ID!) {
    customer(id: $id) {
      ... on Customer {
        firstName
        lastName
        emailAddress
        phoneNumber
      }
    }
  }
`;

const EMAIL_PATTERN = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

type Props = {
  order: Order;
};

const UserInformation: FC<Props> = ({ order }) => {
  const dataService = useInjector(DataService);
  const [isLoading, setIsLoading] = React.useState(false);
  const {
    handleSubmit,
    control,
    setValue,
    register,
    formState: { errors, isDirty, defaultValues },
    reset,
  } = useForm();

  useEffect(() => {
    async function loadUser() {
      if (!order.customer) return;

      const customer = await firstValueFrom(
        dataService
          .query(GET_CUSTOMER, {
            id: order.customer.id,
          })
          .mapSingle((data: any) => data?.customer),
      );

      const { emailAddress, firstName, lastName, phoneNumber } = customer;

      reset(
        {
          emailAddress,
          firstName,
          lastName,
          phoneNumber,
        },
        { keepIsSubmitted: true },
      );
    }

    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedFetch = async (filter: string) => {
    return await firstValueFrom(
      dataService
        .query(GET_CUSTER_LIST, {
          options: {
            take: 10,
            skip: 0,
            filter: {
              emailAddress: {
                contains: filter,
              },
            },
          },
        })
        .mapSingle((data: any) => data?.customers?.items)
        .pipe(
          map((customers: any[]) =>
            customers.map((customer) => ({
              value: customer.emailAddress,
              label: customer.emailAddress,
              customer,
            })),
          ),
        ),
    );
  };

  const onCustomerSelect = (val: any) => {
    const { customer } = val;

    if (customer) {
      if (customer.firstName) {
        setValue('firstName', customer.firstName);
      }
      if (customer.lastName) {
        setValue('lastName', customer.lastName);
      }
      if (customer.phoneNumber) {
        setValue('phoneNumber', customer.phoneNumber);
      }
    }

    if (EMAIL_PATTERN.test(val?.label)) {
      setValue('emailAddress', val?.label);
    } else {
      setValue('emailAddress', '');
    }
  };

  const onCreateCustomer = (value: string) => {
    if (EMAIL_PATTERN.test(value.trim())) {
      setValue('emailAddress', value.trim());
    } else {
      setValue('emailAddress', `${value.trim()}@instagram.com`);
    }
  };

  const onSubmit = (data: any) => {
    setIsLoading(true);
    dataService.order
      .setCustomerForDraftOrder(order.id as string, { input: data })
      .subscribe({
        complete: () => {
          reset(data, { keepDirty: false });
          setIsLoading(false);
        },
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="emailAddress"
        rules={{
          required: true,
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: `Email має бути корекнтним`,
          },
        }}
        render={({ field: { value, onChange } }) => {
          return (
            <AsyncCreatableSelect
              options={[]}
              className="custom-auto-complete"
              placeholder="Введіть email або instagram ім'я"
              value={value ? { label: value, value } : null}
              onChange={(val) => {
                onCustomerSelect(val);
                onChange(val?.value || null);
              }}
              isClearable
              onCreateOption={onCreateCustomer}
              loadOptions={debouncedFetch}
            />
          );
        }}
      />

      <div
        style={{
          gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
          display: 'grid',
          rowGap: 10,
          columnGap: 20,
          marginTop: 10,
        }}
      >
        <FormField label="Прізвище">
          <input
            {...register('lastName', {
              required: true,
              pattern: {
                value: /^[^\s]+(?:$|.*[^\s]+$)/,
                message: `Прізвище не може мати пробілів на початку або в кінці, або бути порожнім рядком.`,
              },
            })}
            placeholder="Прізвище"
            required
            type="text"
          />
        </FormField>
        <FormField label="Ім'я">
          <input
            {...register('firstName', {
              required: true,
              pattern: {
                value: /^[^\s]+(?:$|.*[^\s]+$)/,
                message: `Ім'я не може мати пробілів на початку або в кінці, або бути порожнім рядком.`,
              },
            })}
            placeholder="Ім'я"
            required
            type="text"
          />
        </FormField>
        <FormField label="Телефон">
          <PhoneInput
            name="phoneNumber"
            international
            withCountryCallingCode
            defaultCountry="UA"
            placeholder="Телефон"
            label="Телефон"
            defaultValue=""
            control={control}
            autoComplete="tel"
            required
            aria-describedby="phone-error"
            aria-invalid={!!errors['phone-error']}
            rules={{
              required: true,
              validate: isValidPhoneNumber,
            }}
          />
        </FormField>
      </div>

      <SaveButton
        isDirty={isDirty}
        isLoading={isLoading}
        hasInitialValues={!!defaultValues?.emailAddress}
      />
    </form>
  );
};

export default UserInformation;
