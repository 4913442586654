import { timesCircleIcon } from '@cds/core/icon';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { CdsIcon } from '@vendure/admin-ui/react';
import { Asset } from '@vendure/core';
import React, { FC } from 'react';

type Props = {
  id: string;
  asset: Asset;
  onRemove: (id: string) => void;
};

export const SortableImage: FC<Props> = ({ id, asset, onRemove }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const handleRemove = () => {
    onRemove(id);
  };

  return (
    <div
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        position: 'relative',
        transition,
      }}
      {...attributes}
      {...listeners}
    >
      <img
        src={asset.preview}
        alt={asset.name}
        style={{ width: 100, height: 100, marginRight: 8 }}
      />
      <button
        className="button-ghost"
        onClick={handleRemove}
        style={{
          position: 'absolute',
          top: 3,
          right: 13,
          padding: 3,
          zIndex: 99,
        }}
      >
        <CdsIcon icon={timesCircleIcon} />
      </button>
    </div>
  );
};
