import { Card, useDetailComponentData } from '@vendure/admin-ui/react';
import { Order } from '@vendure/core';
import React, { FC, useEffect } from 'react';

import UserInformation from './UserInformation';

const ClientWidget: FC = () => {
  const { entity } = useDetailComponentData<Order>();

  useEffect(() => {
    const sidebarElement = document.querySelector('.sidebar');
    if (sidebarElement) {
      const vdrCardChildren =
        sidebarElement.querySelectorAll<HTMLDivElement>('vdr-card');
      for (let i = 0; i < Math.min(vdrCardChildren.length, 3); i++) {
        vdrCardChildren[i].style.display = 'none';
      }
      if (vdrCardChildren.length > 3) {
        vdrCardChildren[3].style.marginTop = '0px';
      }
    }
  }, []);

  if (!entity?.id) return null;

  return (
    <Card title="Клієнт">
      <UserInformation order={entity} />
    </Card>
  );
};

export default ClientWidget;
