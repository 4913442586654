import { plusIcon } from '@cds/core/icon';
import { CdsButton } from '@cds/react/button';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  MouseSensor,
} from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import {
  AssetPickerDialogComponent,
  DataService,
  GetShippingMethodListDocument,
  ModalService,
} from '@vendure/admin-ui/core';
import { GetShippingMethodListQuery } from '@vendure/admin-ui/core/common/generated-types';
import {
  Card,
  CdsIcon,
  useDetailComponentData,
  useInjector,
  useQuery,
} from '@vendure/admin-ui/react';
import { Asset, ProductVariant } from '@vendure/core';
import gql from 'graphql-tag';
import React, { FC, useEffect, useMemo } from 'react';
import { lastValueFrom } from 'rxjs';

import AddNewProductVariantPart from './AddNewPoductVariantPart/AddNewProductVariantPart';
import ProductVariantPartForm from './AddNewPoductVariantPart/ProductVariantPartForm/ProductVariantPartForm';
import { SortableImage } from './AddNewPoductVariantPart/ProductVariantPartForm/ProductVariantPartImage/SortableImage';
import ProductVariantPartList from './ProductVariantPartList/ProductVariantPartList';

const ProductVariantPartWidget: FC = () => {
  const { entity } = useDetailComponentData<any>();

  if (!entity?.id) return null;

  return (
    <div style={{ marginBottom: 16 }}>
      <Card title="Комплектуючі">
        <ProductVariantPartList id={entity.id} />
        <AddNewProductVariantPart parentProductVariantId={entity.id} />
      </Card>
    </div>
  );
};

export default ProductVariantPartWidget;
