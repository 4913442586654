import {
  closestCenter,
  DndContext,
  MouseSensor,
  useSensor,
} from '@dnd-kit/core';
import {
  restrictToHorizontalAxis,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { DataService } from '@vendure/admin-ui/core';
import { useInjector, useMutation } from '@vendure/admin-ui/react';
import { ID } from '@vendure/core';
import gql from 'graphql-tag';
import React, { FC, useEffect } from 'react';

import {
  GetProductVariantPartsQuery,
  ProductVariantPart,
  RemoveProductVariantPartMutation,
  UpdateProductVariantPartsPositionMutation,
} from '../../../graphql/generated-admin-types';
import EditProductVariantPart from '../EditProductVariantPart/EditProductVariantPart';

import ProductVariantListItem from './ProductVariantListItem';

export const GET_PRODUCT_VARIANT_PARTS = gql`
  query GetProductVariantParts($productVariantId: ID!) {
    productVariantParts(productVariantId: $productVariantId) {
      id
      featuredAsset {
        id
        preview
        name
      }
      assets {
        id
        preview
        name
      }
      partProductVariant {
        id
        name
      }
      translations {
        languageCode
        name
        description
      }
    }
  }
`;

const REMOVE_PRODUCT_VARIANT_PART = gql`
  mutation RemoveProductVariantPart($productVariantPartId: ID!) {
    removeProductVariantPart(productVariantPartId: $productVariantPartId) {
      __typename
    }
  }
`;

const UPDATE_PRODUCT_VARIANT_PARTS_POSITION = gql`
  mutation UpdateProductVariantPartsPosition(
    $input: UpdateProductVariantPartPositionInput!
  ) {
    updateProductVariantPartPositions(input: $input) {
      id
      featuredAsset {
        id
        preview
        name
      }
      assets {
        id
        preview
        name
      }
      partProductVariant {
        id
        name
      }
      translations {
        languageCode
        name
        description
      }
    }
  }
`;

type Props = {
  id: ID;
};

const ProductVariantPartList: FC<Props> = ({ id }) => {
  const dataService = useInjector(DataService);
  const [editId, setEditId] = React.useState<string>();
  const [languageCode, setLanguageCode] = React.useState<string>();
  const [parts, setParts] = React.useState<ProductVariantPart[]>([]);
  const [updateProductVariantPartPositions] =
    useMutation<UpdateProductVariantPartsPositionMutation>(
      UPDATE_PRODUCT_VARIANT_PARTS_POSITION,
    );
  const [removePart] = useMutation<RemoveProductVariantPartMutation>(
    REMOVE_PRODUCT_VARIANT_PART,
  );
  const sensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  useEffect(() => {
    const subscription = dataService
      .query<GetProductVariantPartsQuery>(GET_PRODUCT_VARIANT_PARTS, {
        productVariantId: id,
      })
      .stream$.subscribe(({ productVariantParts }) => {
        setParts(productVariantParts as ProductVariantPart[]);
      });
    return () => subscription.unsubscribe();
  }, [dataService, id]);

  useEffect(() => {
    const subscription = dataService.client
      .uiState()
      .stream$.subscribe(({ uiState }) =>
        setLanguageCode(uiState.contentLanguage),
      );

    return () => subscription.unsubscribe();
  }, [dataService.client]);

  const handleDragEnd = async (event: any) => {
    if (!parts.length) return;
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = parts.findIndex((item) => item.id === active.id);
      const newIndex = parts.findIndex((item) => item.id === over.id);

      const reorderedParts = arrayMove(parts, oldIndex, newIndex);

      const items = reorderedParts.map((item, index) => ({
        id: item.id,
        position: ++index,
      }));

      setParts(reorderedParts);

      const updatedParts = await updateProductVariantPartPositions({
        input: { items },
      });
      setParts(
        updatedParts.updateProductVariantPartPositions as ProductVariantPart[],
      );
    }
  };

  if (!parts.length || !languageCode) return null;

  return (
    <>
      <div
        style={{
          display: 'grid',
          rowGap: 5,
          marginBottom: 15,
        }}
      >
        <DndContext
          sensors={[sensor]}
          modifiers={[restrictToVerticalAxis]}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={parts} strategy={verticalListSortingStrategy}>
            {parts.map((item) => (
              <ProductVariantListItem
                key={item.id}
                item={item}
                languageCode={languageCode}
                onEditClick={setEditId}
                onRemoveClick={async (productVariantPartId: string) => {
                  await removePart({
                    productVariantPartId,
                  });
                  dataService
                    .query<GetProductVariantPartsQuery>(
                      GET_PRODUCT_VARIANT_PARTS,
                      {
                        productVariantId: id,
                      },
                    )
                    .single$.subscribe(({ productVariantParts }) => {
                      setParts(productVariantParts as ProductVariantPart[]);
                    });
                }}
              />
            ))}
          </SortableContext>
        </DndContext>
      </div>
      {editId !== undefined && (
        <EditProductVariantPart
          key={editId}
          productVariantPart={
            parts.find((item) => item.id === editId) as ProductVariantPart
          }
          parentProductVariantId={id}
          onClose={() => setEditId(undefined)}
        />
      )}
    </>
  );
};

export default ProductVariantPartList;
