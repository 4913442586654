import {
  DataService,
  Country,
  GetShippingMethodListDocument,
} from '@vendure/admin-ui/core';
import { GetShippingMethodListQuery } from '@vendure/admin-ui/core/common/generated-types';
import {
  Card,
  useDetailComponentData,
  useInjector,
} from '@vendure/admin-ui/react';
import { Order } from '@vendure/core';
import React, { FC, useEffect, useState } from 'react';
import { lastValueFrom } from 'rxjs';

import { ManualShippingPriceResult } from '../../graphql/generated-admin-types';

import ShippingForm, { ShippingFormValues } from './ShippingForm/ShippingForm';
import { ShippingMethodWithHandlerCode } from './ShippingForm/ShippingMethodSelect/ShippingMethodSelect';
import { ManualShippingPrice } from './ShippingForm/ShippingPrice/ShippingPrice';

const ShippingWidget: FC = () => {
  const dataService = useInjector(DataService);
  const { entity } = useDetailComponentData<Order>();

  const [defaultValues, setDefaultValues] = useState<ShippingFormValues>();
  const [eligibleShippingMethods, setEligibleShippingMethods] =
    useState<ShippingMethodWithHandlerCode[]>();
  const [availableCountries, setAvailableCountries] =
    useState<Array<Partial<Country>>>();

  useEffect(() => {
    async function loadData() {
      if (!entity?.id) return;

      const orderId = entity.id.toString();

      const eligibleShippingMethodsPromise = lastValueFrom(
        dataService.order.getDraftOrderEligibleShippingMethods(orderId).single$,
      );
      const shippingMethodListsPromise =
        lastValueFrom<GetShippingMethodListQuery>(
          dataService.query(GetShippingMethodListDocument).single$,
        );
      const availableCountriesPromise = lastValueFrom(
        dataService.settings.getAvailableCountries().single$,
      );
      const manualShippingPricePromise = lastValueFrom(
        dataService.query<{
          manualShippingPrice: ManualShippingPriceResult;
        }>(ManualShippingPrice, { orderId }).single$,
      );

      const [
        { eligibleShippingMethodsForDraftOrder },
        {
          manualShippingPrice: { price },
        },
        { countries },
        { shippingMethods },
      ] = await Promise.all([
        eligibleShippingMethodsPromise,
        manualShippingPricePromise,
        availableCountriesPromise,
        shippingMethodListsPromise,
      ]);

      setAvailableCountries(countries.items);
      setEligibleShippingMethods(
        eligibleShippingMethodsForDraftOrder.map((method) => ({
          ...method,
          fulfillmentHandlerCode: shippingMethods.items.find(
            (sm) => sm.id === method.id,
          )?.fulfillmentHandlerCode,
        })),
      );
      setDefaultValues({
        manualShippingPrice: price ? String(price / 100) : '',
        shippingMethodId:
          entity.shippingLines[0]?.shippingMethod?.id.toString() || '',
        countryCode: entity.shippingAddress?.countryCode || '',
        postalCode: entity.shippingAddress?.postalCode || '',
        province: entity.shippingAddress?.province || '',
        city: entity.shippingAddress?.city || '',
        streetLine1: entity.shippingAddress?.streetLine1 || '',
      });
    }

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataService, entity?.id]);

  if (!entity?.id || !defaultValues) return null;

  return (
    <Card title="Доставка">
      <ShippingForm
        shippingMethods={eligibleShippingMethods}
        availableCountries={availableCountries}
        defaultValues={defaultValues}
        orderId={entity.id.toString()}
      />
    </Card>
  );
};

export default ShippingWidget;
