import { FormField } from '@vendure/admin-ui/react';
import { gql } from 'graphql-tag';
import React, { FC, useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { ShippingMethodWithHandlerCode } from '../ShippingMethodSelect/ShippingMethodSelect';

export const ManualShippingPrice = gql`
  query ManualShippingPrice($orderId: ID!) {
    manualShippingPrice(orderId: $orderId) {
      price
    }
  }
`;

type Props = {
  shippingMethods?: ShippingMethodWithHandlerCode[];
};

const ShippingPrice: FC<Props> = ({ shippingMethods }) => {
  const { register, control, resetField } = useFormContext();
  const shippingMethodId = useWatch({
    name: 'shippingMethodId',
    control,
  });

  const hasManualShippingPrice = useMemo(
    () =>
      shippingMethods?.some(
        (method) =>
          method.id === shippingMethodId &&
          method.metadata?.withManualShippingPrice,
      ),
    [shippingMethodId, shippingMethods],
  );

  useEffect(() => {
    if (hasManualShippingPrice) return;
    resetField('manualShippingPrice');
  }, [hasManualShippingPrice, resetField]);

  if (!hasManualShippingPrice) return null;

  return (
    <div style={{ marginTop: 10 }}>
      <FormField label="Ціна доставки">
        <input
          {...register('manualShippingPrice')}
          placeholder="Ціна доставки"
          type="number"
        />
      </FormField>
    </div>
  );
};

export default ShippingPrice;
